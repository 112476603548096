export default {
  "appFooterMessageOr": "或",
  "appFooterMessagePost": "。謝謝。",
  "appFooterMessagePre": "如有查詢，可以文字訊息聯絡「自在食」熱線：",

  "appHeaderTitleMain": "「賽馬會逆境同行食物援助計劃」",

  "appHeadertitleSub": "惜食堂「自在食」自助速凍餐提取機服務",

  "componentOctopusInputNoCheckDigitCheckedLabel": "如八達通卡號沒有括號的數字, 請勾選此欄",

  "componentUploadFileButtonLabel": "選擇圖片",
  "componentUploadFileOpenFileButtonLabel": "在新視窗打開文件",
  "componentUploadFilePreviewTitle": "預覽文件",
  "componentUploadFileRemoveConfirmMessage": "請定要刪除這個圖片嗎?",

  "errorMessageRequiredInputAny": "請輸入%{name}",
  "errorMessageRequiredSelectAny": "請選擇%{name}",
  "errorMessageRequiredUploadAny": "請上傳%{name}",
  "errorMessageUnknownError": "未知錯誤, 請稍後重試",

  "page1ScreenButtonSubmitLabel": "確定",
  "page1ScreenErrorMessageInvalidToken": "驗証碼不正確, 請檢查清楚你的短訊息",
  "page1ScreenErrorMessageInvalidUser": "抱歉, 找不到此申請記錄。如有問題請聯絡我們",
  "page1ScreenFormAlertContentPost": "。請勿隨時分享此連結給其他人！",
  "page1ScreenFormAlertContentPre": "此申請表格只屬於申請人",
  "page1ScreenFormAlertTitle": "請注意",
  "page1ScreenFormTokenLabel": "四位數字驗証碼",
  "page1ScreenFormTokenLengthErrorMessage": "請輸入四位數字驗証碼",
  "page1ScreenHeaderSub": "「自在食」申請程序",
  "page1ScreenHeaderSubRenewal": "「自在食」延續服務申請程序",
  "page1ScreenHeaderSubRight": "申請人: %{name}",
  "page1ScreenSuccessMessage": "驗証碼正確",
  "page1ScreenTitle": "請輸入短訊內的驗証碼, 才可繼續申請程序",

  "page2ScreenButtonSubmitLabel": "下一步",
  "page2ScreenFormStatusErrorMessage": "請標明申請意向",
  "page2ScreenFormStatusLabel": "請標明申請意向：",
  "page2ScreenFormStatusOptionConfirm": "確定",
  "page2ScreenFormStatusOptionReject": "拒絕",
  "page2ScreenFormStatusOptionSelectEFoodBank": "E+ 食",
  "page2ScreenFormStatusOptionSelectHotMeal": "趁熱食",
  "page2ScreenIntroduction1": "惜食堂「自在食」自助速凍餐提取機計劃為「賽馬會逆境同行食物援助計劃」中的長期服務，成功申請成為會員，可用二維碼或已登記的八達通卡，於不同區域的自助機，領取長達一年的速凍餐，自行在家翻熱享用。",
  "page2ScreenIntroduction2": "自助機位置請參閱惜食堂網頁： ",
  "page2ScreenIntroduction3": "請於 *5個工作天內* 完成以下登記程序 ，否則系統會自動取消你的服務申請 。服務申請一經取消，你需要重新網上登記及輪候。",
  "page2ScreenIntroductionOption1": "我確定申請「自在食」服務，並會五天內上載所需文件以完成計劃申請。",
  "page2ScreenIntroductionOption2": "我拒絕申請「自在食」服務。我了解申請流程將在此結束，並會收到由賽馬會系統送出的服務取消短訊。",
  "page2ScreenIntroductionOption3": "我想轉為申請齊惜福的「趁熱食」服務，了解惜食堂職員會跟進轉介。",
  "page2ScreenIntroductionOption4": "我想申請短期服務「E+ 食」，並了解申請表內已輸入的資料不符合短期服務條件，惜食堂職員將終止我的「自在食」服務申請，我會自行重新網上申請「E+ 食」服務。",
  "page2ScreenIntroductionRenewal1": "請於五天內完成文件上載, 提交申請, 否則會按原定日期(開始服務起滿52週)結束自在食服務",
  "page2ScreenIntroductionRenewal2": "請留意, 可獲續期人數有限, 惜食堂社工團隊會按照家庭入息狀況進行審核, 審核需時,請耐心等候, 稍後會以短訊通知合資格會員有關安排",
  "page2ScreenIntroductionRenewalOption1": "我知悉服務結束日期, 現在沒有食物援助需要, 毋須延續服務",
  "page2ScreenIntroductionRenewalOption2": "我仍然有食物援助需要,希望申請自在食計劃延期, 並會上載申請文件",
  "page2ScreenStatusModalConfirmButtonCancelLabel": "返回",
  "page2ScreenStatusModalConfirmButtonConfirmLabel": "確定要繼續",
  "page2ScreenStatusModalConfirmContentReject": "申請流程將在此結束。你將收到由賽馬會系統送出的服務取消短訊。",
  "page2ScreenStatusModalConfirmContentSelectEfoodBank": "由於申請表內已輸入的資料不符合短期服務條件，職員會為你終止「自在食」申請，你需自行重新網上申請「E+ 食」服務。",
  "page2ScreenStatusModalConfirmContentSelectHotMeal": "我們同事會跟進轉介。",
  "page2ScreenStatusModalConfirmTitleReject": "你選擇了拒絕申請「自在食」",
  "page2ScreenStatusModalConfirmTitleSelectEfoodBank": "你選擇了申請短期服務「E+ 食」",
  "page2ScreenStatusModalConfirmTitleSelectHotMeal": "你選擇了申請齊惜福的「趁熱食」服務",
  "page2ScreenStatusRenewalModalConfirmContentReject": "確定知悉服務結束日期, 毋須申請延續服務",
  "page2ScreenStatusRenewalModalConfirmTitleReject": "你選擇了不需要申請延續服務",
  "page2ScreenSubmitModalButtonCancelLabel": "返回",
  "page2ScreenSubmitModalButtonConfirmLabel": "確定提交",
  "page2ScreenSubmitModalContent": "你確定提交申請資料? 提交後不能再修改",
  "page2ScreenSubmitModalTitle": "提交申請資料",
  "page2ScreenTitle": "服務簡介及文件上載",

  "page3ScreenAutosavingMessageSaveButtonLabel": "保存",
  "page3ScreenAutosavingMessageSaved": "資料已自動保存",
  "page3ScreenAutosavingMessageSaving": "自動保存中...",
  "page3ScreenAutosavingMessageUnsaved": "目前有資料未保存",
  "page3ScreenButtonSubmitLabel": "提交",
  "page3ScreenErrorMessageInvalidToken": "操作超時, 請重新登入",
  "page3ScreenFoodSafetyCheckboxButtonErrorMessage": "請先打開聲明並閱讀後, 才能勾選此項",
  "page3ScreenFoodSafetyCheckboxButtonLabel": "按此查看食安聲明",
  "page3ScreenFoodSafetyCheckboxText": "本人已閱讀並同意食安聲明",
  "page3ScreenFormAddressProofImage": "住址證明",
  "page3ScreenFormFoodSafetyErrorMessage": "請同意食安聲明",
  "page3ScreenFormFoodSafetyLabel": "食安聲明",
  "page3ScreenFormIncomeProofAmountErrorMessageInvalid": "請輸入正確金額",
  "page3ScreenFormIncomeProofAmountLabel": "家庭入息金額",
  "page3ScreenFormIncomeProofClaimContentPost": "聲明以上所填報的事項，全部屬實，正確無訛。本人明白及同意如作出虛假陳述或提供虛假資料以騙取食物援助，將導致本人及家庭成員喪失接受服務資格，而有關機構亦保留一切追究權利。",
  "page3ScreenFormIncomeProofClaimContentPre": "本人",
  "page3ScreenFormIncomeProofClaimFullNameLabel": "申請人全名",
  "page3ScreenFormIncomeProofClaimIdCardErrorMessage": "請輸入有效身份證號碼",
  "page3ScreenFormIncomeProofClaimIdCardLabel": "身份證號碼",
  "page3ScreenFormIncomeProofClaimLabel": "收入申報聲明",
  "page3ScreenFormIncomeProofExceptionReasonErrorMessage": "請輸入原因",
  "page3ScreenFormIncomeProofExceptionReasonIndices0Label": "沒有收入",
  "page3ScreenFormIncomeProofExceptionReasonIndices1Label": "現金出糧",
  "page3ScreenFormIncomeProofExceptionReasonIndices2Label": "支票出糧",
  "page3ScreenFormIncomeProofExceptionReasonIndices3Label": "自僱人士",
  "page3ScreenFormIncomeProofExceptionReasonIndices4Label": "遺失糧單",
  "page3ScreenFormIncomeProofExceptionReasonIndices5Label": "其他（請填寫原因）",
  "page3ScreenFormIncomeProofExceptionReasonIndices6Label": "遺失月結單",
  "page3ScreenFormIncomeProofExceptionReasonIndicesLabel": "若以上任何月份未能提供文件，請列明原因 （可選多個一項）",
  "page3ScreenFormIncomeProofImageLabel": "入息證明圖片",
  "page3ScreenFormIncomeProofImageTooltip": "如未能提供文件, 請在下面選擇原因便能跳過此步驟",
  "page3ScreenFormIncomeProofPeriodAfter1Label": "受疫情影響後 及 申請服務時最近3個月的家庭入息 (第一個月)",
  "page3ScreenFormIncomeProofPeriodAfter2Label": "受疫情影響後 及 申請服務時最近2個月的家庭入息 (第二個月)",
  "page3ScreenFormIncomeProofPeriodAfter3Label": "受疫情影響後 及 申請服務時最近1個月的家庭入息 (第三個月)",
  "page3ScreenFormIncomeProofPeriodAfterRenewalLabel": "%{year}年%{month}月 家庭入息金額/入息證明文件",
  "page3ScreenFormIncomeProofPeriodBeforeLabel": "受疫情影響前的家庭入息",
  "page3ScreenFormIncomeProofYearAndMonthErrorMessage": "請選擇時段",
  "page3ScreenFormIncomeProofYearAndMonthLabel": "選擇時段",
  "page3ScreenFormMemberIdCardCheckedLabel": "文件已認證",
  "page3ScreenFormMemberIdCardImage": "身份證明文件",
  "page3ScreenFormMemberNameChinese": "家庭成員英文名稱",
  "page3ScreenFormMemberTitle": "第 %{index} 位成員",
  "page3ScreenFormMembersCountLabel": "申請家庭人數",
  "page3ScreenFormOctopusCardErrorMessage": "請輸入有效八達通編號",
  "page3ScreenFormOctopusCardErrorMessageDuplicate": "八達通號重覆了",
  "page3ScreenFormOctopusCardLabel": "八達通卡編號",
  "page3ScreenFormOctopusCardNoneCheckedLabel": "暫不提供八達通號",
  "page3ScreenFormOctopusCardNoneCheckedText": "暫不提供",
  "page3ScreenFormOctopusCardTitle": "第 %{index} 個八達通",
  "page3ScreenFormPersonalInfoClaimButtonLabel": "按此查看使用者收集私隱聲明",
  "page3ScreenFormPersonalInfoClaimCheckboxButtonErrorMessage": "請先打開聲明並閱讀後, 才能勾選此項",
  "page3ScreenFormPersonalInfoClaimCheckboxText": "本人已閱讀並同意使用者收集私隱聲明",
  "page3ScreenFormPersonalInfoClaimErrorMessage": "請同意個人資料收集聲明",
  "page3ScreenFormPersonalInfoClaimLabel": "個人資料收集聲明",
  "page3ScreenFormPersonalInfoClaimTextLinkLabel": "個人資料收集聲明",
  "page3ScreenFormPersonalInfoClaimTextPre": "本人已閱讀並同意",
  "page3ScreenFormPreferredKioskAreaLabel": "自助機地區",
  "page3ScreenFormPreferredKioskDisabledLabel": "(暫時額滿)",
  "page3ScreenFormPreferredKioskLabel": "首選自助機",
  "page3ScreenFormSectionAddressProofDescription": "請上載住址證明圖片 （接受水費單/電費單/租單/銀行信件, jpeg/png/pdf 格式）",
  "page3ScreenFormSectionAddressProofTitle": "住址證明",
  "page3ScreenFormSectionFoodSafetyAndPersonalInfoClaimTitle": "食安及個人資料收集聲明",
  "page3ScreenFormSectionIncomeProofDescription": "請填寫並上載疫情前後的家庭收入證明。\n必須填寫受疫情影響前/後的入息金額，若失業可輸入 $0。\n以家庭為單位，若家庭中於該月份有多過1人有工作，請於該月份填寫所有家庭成員的總入息，及提供相關入息證明。\n入息證明接受銀行存摺、月結單、糧單、出糧紀錄，並需清楚顯示入息金額",
  "page3ScreenFormSectionIncomeProofDescriptionRenewal": "請填寫並上載最近三個月收入證明。必須填寫入息金額，若失業可輸入 $0。\n以家庭為單位，若家庭中於該月份有多過1人有工作，請於該月份填寫所有家庭成員的總入息，及提供相關入息證明。\n入息證明接受銀行存摺、月結單、糧單、出糧紀錄，並需清楚顯示入息金額\n",
  "page3ScreenFormSectionIncomeProofTitle": "家庭收入證明",
  "page3ScreenFormSectionMemberDescription": "請填寫各家庭成員中文名稱及上載所有家庭成員香港身份證明文件 (jpeg/png/pdf 格式)",
  "page3ScreenFormSectionMemberTitle": "家庭成員",
  "page3ScreenFormSectionOctopusCardsDescription": "會員可用二維碼或已登記的八達通, 於自助機取餐. 請輸入1至2張八達通卡號碼, 以作登記",
  "page3ScreenFormSectionOctopusCardsTitle": "登記八達通會員卡",
  "page3ScreenFormSectionPreferredKioskDescription": "會員可以選擇從任何區域的自助機取餐, 請選擇首選機以製作會員卡",
  "page3ScreenFormSectionPreferredKioskTitle": "選擇首選自助機",
  "page3ScreenHeaderInfo": "請確保所有上載照片畫面清晰, 否則可視為失效",
  "page3ScreenIncompleteFormMessage": "你有部份資料未齊全，請返回檢查一次",
  "page3ScreenInvalidIncomeAmountFormMessage": "未能顯示家庭收入受疫情影響而減少，不符合申請資格。（家庭收入需受疫情影響而減少及最近三個月的平均入息上限: $%{amount})",
  "page3ScreenInvalidIncomeAmountFormTitle": "提交失敗，請重新填寫入息資料",
  "page3ScreenMessageSaved": "保存成功",
  "page3ScreenMessageSaving": "自動保存中...",
  "page3ScreenOctopusHelpModalText": "如使用實體八達通卡，可查看卡的正面或反面，請輸入8位或9位之完整編號，當附有括號號碼時也須一併填寫。",
  "page3ScreenOctopusHelpModalTitle": "如何找到八達通卡號",

  "page4ScreenResultExpiredExtraButton": "前往計劃網頁",
  "page4ScreenResultSubTitleAccepted": "你的申請已成功批核，惜食堂團隊將通知「自在食」服務開始日期，請耐心等候。",
  "page4ScreenResultSubTitleApproved": "您的申請仍在審批階段，請耐心等候。",
  "page4ScreenResultSubTitleExpired": "由於已過上載文件期限，「自在食」服務申請已被終止。謝謝。若重新申請，請於計劃網頁重新填寫資料。",
  "page4ScreenResultSubTitleNotApproved": "因個案不符合計劃條件，你的申請已被拒絕。若選擇其他食物援助服務，請於計劃網頁重新填寫資料。",
  "page4ScreenResultSubTitleRejected": "惜食堂團隊會按照你的申請意向，於五個工作天內於系統取消您的申請。申請取消後，您將會收到由賽馬會系統送出的服務取消短訊。謝謝。",
  "page4ScreenResultSubTitleSelectedEfoodBank": "由於申請表內已輸入的資料不符合短期服務條件，惜食堂團隊會於五個工作天內於系統終止您的「自在食」申請，您將會收到由賽馬會系統送出的服務取消短訊。您需自行重新網上申請「E+食」服務，而個案會交由系統安排的慈善團體跟進。謝謝",
  "page4ScreenResultSubTitleSelectedHotMeal": "惜食堂團隊會按照你的申請意向，於五個工作天內將你的申請轉介予齊惜福跟進。由於申請人數眾多，申請轉介後亦請耐心等候。謝謝。",
  "page4ScreenResultSubTitleSubmitted": "惜食堂團隊會按申請人遞交完整文件的次序處理審批，由於申請人數眾多，審批需時，請耐心等候。批核成功後，將會獲發短訊，通知服務開始日期及其他會員資料。謝謝。",
  "page4ScreenResultTitleAccepted": "申請已被批核",
  "page4ScreenResultTitleApproved": "申請仍在審批",
  "page4ScreenResultTitleExpired": "上載文件已過時",
  "page4ScreenResultTitleNotApproved": "申請已被拒絕",
  "page4ScreenResultTitleRejected": "已確定終止「自在食」計劃申請",
  "page4ScreenResultTitleSelectedEfoodBank": "已確定你選擇取消申請「自在食」服務，並會自行於網頁重新申請短期服務「E+ 食」",
  "page4ScreenResultTitleSelectedHotMeal": "已確定申請「趁熱食」服務",
  "page4ScreenResultTitleSubmitted": "已完成上載所需文件及參閱須知"
}