import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { useSelector, useDispatch } from 'react-redux';
import { List, Card, Typography, Input, Button, Form, Select, Modal, DatePicker,
  Spin, Radio, message, Space, Divider, Alert, Avatar, Checkbox
} from 'antd';
import { UploadOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import moment from 'moment';

import { updateForm } from '../../reducers/formSlice';
import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';
import UploadFile from '../../components/UploadFile';
import OctopusInput from '../../components/OctopusInput';
import ForceOpeningCheckbox from '../../components/ForceOpeningCheckbox';
import HKIDInput from '../../components/HKIDInput';
import Page3ScreenOctopusHelpModalButton from './Page3ScreenOctopusHelpModalButton';
import Page3ScreenAutosavingMessage from './Page3ScreenAutosavingMessage';

const {Title, Text, Paragraph} = Typography
const {Option} = Select

const formLayout = {
  labelCol: {
    md: { span: 8 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 8, span: 16 },
  },
};

const formFullLayout = {
  wrapperCol: {
    md: { span: 24 },
  },
};

export default function Page3Screen (props) {
  const {signupFormId} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const formData = useSelector(state => state.form.value);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [kiosksByArea, setKiosksByArea] = useState(null);
  const [preferredKioskIdAreaId, setPreferredKioskIdAreaId] = useState(null);
  const [autosavingStatus, setAutosavingStatus] = useState('SAVED');
  const [hasSkippedFirstAutosaving, setHasSkippedFirstAutosaving] = useState(false);
  const autosaveTimeout = useRef(null);

  const {
    personalInfoCollectionAgreementFileZh,
    personalInfoCollectionAgreementFileEn,
    foodSafetyAgreementFileZh,
    foodSafetyAgreementFileEn,
  } = window.getConfig();
  const personalInfoCollectionAgreementFile = formData.locale === 'en' ? personalInfoCollectionAgreementFileEn : personalInfoCollectionAgreementFileZh;

  useEffect(() => {
    (async () => {
      try {
        // load members
        const members = [];
        for (let memberId of formData.memberIds) {
          const memberRes = await ToolRequest.request('GET', `/v2/admin/signup-form-family-member/${memberId}`);
          members.push(memberRes);
        }

        dispatch(updateForm({
          members,
        }));

        // load kiosks
        const res = await ToolRequest.request('GET', `/v2/admin/signup-form/${signupFormId}/kiosk-vending`);
        const kiosks = res.data.filter(kiosk => kiosk.isEnable);

        const kiosksByAreaNew = [];
        for (let kiosk of kiosks) {
          let kioskArea = kiosksByAreaNew.find(area => area.id === kiosk.area.id);

          if (!kioskArea) {
            kioskArea = {
              ...kiosk.area,
              kiosks: [],
            };
            kiosksByAreaNew.push(kioskArea);
          }
          kioskArea.kiosks.push(kiosk);
        }
        kiosksByAreaNew.sort((a, b) => {
          if (a.name['en'] < b.name['en']) {
            return -1;
          } else if (a.name['en'] > b.name['en']) {
            return 1;
          }
          return 0;
        });
        setKiosksByArea(kiosksByAreaNew);

        // select preferredKioskId
        if (formData.data.preferredKioskId) {
          let preferredKioskId = undefined;
          for (let area of kiosksByAreaNew) {
            const kioskFound = area.kiosks.find(kiosk => kiosk.id === formData.data.preferredKioskId);
            if (kioskFound) {
              preferredKioskId = kioskFound.id;
              setPreferredKioskIdAreaId(area.id);
              break;
            }
          }

          form.setFieldsValue({
            preferredKioskId,
          });
        }

        setLoaded(true);
      } catch (err) {
        message.error(I18n.t('errorMessageUnknownError'));
      }
    })();

    // cooldown after first loaded
    setTimeout(() => {
      setHasSkippedFirstAutosaving(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (autosavingStatus === 'SAVED') {
      return;
    }

    const beforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [autosavingStatus]);

  const onFinish = async (values) => {
    const periodBeforeAmount = formData.isRenewal ? 999999999 : parseFloat(values.incomeProof.periodBefore.amount);
    const periodAfter1Amount = parseFloat(values.incomeProof.periodAfter1.amount);
    const periodAfter2Amount = parseFloat(values.incomeProof.periodAfter2.amount);
    const periodAfter3Amount = parseFloat(values.incomeProof.periodAfter3.amount);

    if (!formData.isRenewal && !isNaN(periodBeforeAmount) && !isNaN(periodAfter1Amount) && !isNaN(periodAfter2Amount) && !isNaN(periodAfter3Amount)) {
      let incomeCondition = 0;
      if (values.members) {
        if (values.members.length >= 6) {
          incomeCondition = 29400;
        } else if (values.members.length === 5) {
          incomeCondition = 26880;
        } else if (values.members.length === 4) {
          incomeCondition = 26100;
        } else if (values.members.length === 3) {
          incomeCondition = 22360;
        } else if (values.members.length === 2) {
          incomeCondition = 18700;
        } else if (values.members.length === 1) {
          incomeCondition = 11000;
        }
      }

      const periodAfterAverageAmount = Math.floor((periodAfter1Amount + periodAfter2Amount + periodAfter3Amount) / 3);
      if ((periodBeforeAmount <= 0 && periodAfter1Amount <= 0 && periodAfter2Amount <= 0 && periodAfter3Amount <= 0)
          || periodAfterAverageAmount > incomeCondition
          || periodAfterAverageAmount >= periodBeforeAmount) {
        Modal.error({
          title: I18n.t('page3ScreenInvalidIncomeAmountFormTitle'),
          content: I18n.t('page3ScreenInvalidIncomeAmountFormMessage', {
            amount: incomeCondition.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          }),
        });
        return;
      }
    }

    Modal.confirm({
      title: I18n.t('page2ScreenSubmitModalTitle'),
      content: I18n.t('page2ScreenSubmitModalContent'),
      okText: I18n.t('page2ScreenSubmitModalButtonConfirmLabel'),
      okType: 'danger',
      cancelText: I18n.t('page2ScreenSubmitModalButtonCancelLabel'),
      onOk: () => {
        setLoading(true);

        (async () => {
          try {
            const res = await ToolRequest.request('POST', `/v2/admin/signup-form/${signupFormId}/submit`, {
              status: 'SUBMITTED',
            });

            dispatch(updateForm({
              status: 'SUBMITTED',
            }));

            history.push(`/${signupFormId}/page/4`);
          } catch (err) {
            setLoading(false);

            if (err?.response?.data) {
              switch (err.response.data.errorCode) {
                case 'NOT_FOUND': {
                  return message.error(I18n.t('page1ScreenErrorMessageInvalidUser'));
                }
                case 'INVALID_TOKEN': {
                  message.error(I18n.t('page3ScreenErrorMessageInvalidToken'));
                  history.replace(`/${signupFormId}/page/1`);
                  return;
                }
                default:
                  return message.error(I18n.t('errorMessageUnknownError'));
              }
            }
            message.error(I18n.t('errorMessageUnknownError'));
          }
        })();
      },
    });
  }

  const onFinishFailed = () => {
    Modal.error({
      content: I18n.t('page3ScreenIncompleteFormMessage'),
    });
  };

  const autosave = (forceNow) => {
    if (!hasSkippedFirstAutosaving) {
      return;
    }
    setAutosavingStatus('UNSAVED');

    if (autosaveTimeout.current) {
      clearTimeout(autosaveTimeout.current);
    }
    autosaveTimeout.current = setTimeout(async () => {

      setAutosavingStatus('SAVING');

      const values = form.getFieldsValue(true);

      // 格式化 data
      const data = formData.isRenewal ? {
        incomeProof: {
          periodAfter1: {
            year: values.incomeProof.periodAfter1.yearAndMonth ? values.incomeProof.periodAfter1.yearAndMonth.year() : undefined,
            month: values.incomeProof.periodAfter1.yearAndMonth ? values.incomeProof.periodAfter1.yearAndMonth.month() + 1 : undefined,
            amount: isNaN(parseFloat(values.incomeProof.periodAfter1.amount)) ? undefined : parseFloat(values.incomeProof.periodAfter1.amount),
            imageIds: values.incomeProof.periodAfter1.imageIds.filter(v => v && v.id).map(image => image.id),
          },
          periodAfter2: {
            year: values.incomeProof.periodAfter2.yearAndMonth ? values.incomeProof.periodAfter2.yearAndMonth.year() : undefined,
            month: values.incomeProof.periodAfter2.yearAndMonth ? values.incomeProof.periodAfter2.yearAndMonth.month() + 1 : undefined,
            amount: isNaN(parseFloat(values.incomeProof.periodAfter2.amount)) ? undefined : parseFloat(values.incomeProof.periodAfter2.amount),
            imageIds: values.incomeProof.periodAfter2.imageIds.filter(v => v && v.id).map(image => image.id),
          },
          periodAfter3: {
            year: values.incomeProof.periodAfter3.yearAndMonth ? values.incomeProof.periodAfter3.yearAndMonth.year() : undefined,
            month: values.incomeProof.periodAfter3.yearAndMonth ? values.incomeProof.periodAfter3.yearAndMonth.month() + 1 : undefined,
            amount: isNaN(parseFloat(values.incomeProof.periodAfter3.amount)) ? undefined : parseFloat(values.incomeProof.periodAfter3.amount),
            imageIds: values.incomeProof.periodAfter3.imageIds.filter(v => v && v.id).map(image => image.id),
          },
          exceptionReasonIndices: values.incomeProof.exceptionReasonIndices,
          exceptionReason: values.incomeProof.exceptionReason || undefined,
          fullName: values.incomeProof.fullName || undefined,
          idCard: values.incomeProof.idCard || undefined,
        },
      } : {
        incomeProof: {
          periodBefore: {
            year: values.incomeProof.periodBefore.yearAndMonth ? values.incomeProof.periodBefore.yearAndMonth.year() : undefined,
            month: values.incomeProof.periodBefore.yearAndMonth ? values.incomeProof.periodBefore.yearAndMonth.month() + 1 : undefined,
            amount: isNaN(parseFloat(values.incomeProof.periodBefore.amount)) ? undefined : parseFloat(values.incomeProof.periodBefore.amount),
            imageIds: values.incomeProof.periodBefore.imageIds.filter(v => v && v.id).map(image => image.id),
          },
          periodAfter1: {
            year: values.incomeProof.periodAfter1.yearAndMonth ? values.incomeProof.periodAfter1.yearAndMonth.year() : undefined,
            month: values.incomeProof.periodAfter1.yearAndMonth ? values.incomeProof.periodAfter1.yearAndMonth.month() + 1 : undefined,
            amount: isNaN(parseFloat(values.incomeProof.periodAfter1.amount)) ? undefined : parseFloat(values.incomeProof.periodAfter1.amount),
            imageIds: values.incomeProof.periodAfter1.imageIds.filter(v => v && v.id).map(image => image.id),
          },
          periodAfter2: {
            year: values.incomeProof.periodAfter2.yearAndMonth ? values.incomeProof.periodAfter2.yearAndMonth.year() : undefined,
            month: values.incomeProof.periodAfter2.yearAndMonth ? values.incomeProof.periodAfter2.yearAndMonth.month() + 1 : undefined,
            amount: isNaN(parseFloat(values.incomeProof.periodAfter2.amount)) ? undefined : parseFloat(values.incomeProof.periodAfter2.amount),
            imageIds: values.incomeProof.periodAfter2.imageIds.filter(v => v && v.id).map(image => image.id),
          },
          periodAfter3: {
            year: values.incomeProof.periodAfter3.yearAndMonth ? values.incomeProof.periodAfter3.yearAndMonth.year() : undefined,
            month: values.incomeProof.periodAfter3.yearAndMonth ? values.incomeProof.periodAfter3.yearAndMonth.month() + 1 : undefined,
            amount: isNaN(parseFloat(values.incomeProof.periodAfter3.amount)) ? undefined : parseFloat(values.incomeProof.periodAfter3.amount),
            imageIds: values.incomeProof.periodAfter3.imageIds.filter(v => v && v.id).map(image => image.id),
          },
          exceptionReasonIndices: values.incomeProof.exceptionReasonIndices,
          exceptionReason: values.incomeProof.exceptionReason || undefined,
          fullName: values.incomeProof.fullName || undefined,
          idCard: values.incomeProof.idCard || undefined,
        },
        addressProofImageIds: values.addressProofImageIds.filter(v => v && v.id).map(image => image.id),
        preferredKioskId: values.preferredKioskId || undefined,
        octopusCards: values.octopusCards.filter(v => v).map(octopusCard => ({
          card: octopusCard.card || undefined,
          checkDigit: octopusCard.checkDigit || undefined,
          noCheckDigitChecked: !!octopusCard.noCheckDigitChecked,
        })),
        octopusCardsNoneChecked: values.octopusCardsNoneChecked || false,
        foodSafetyChecked: values.foodSafetyChecked || false,
        personalInfoClaimChecked: values.personalInfoClaimChecked || false,
      };

      let membersData = null;

      if (!formData.isRenewal) {
        membersData = values.members.map((member, i) => ({
          id: formData.memberIds[i],
          idCardImageIds: member.idCardImageIds.filter(v => v && v.id).map(image => image.id),
          idCardChecked: member.idCardChecked || false,
        }));
      }

      console.log('autosave', data, membersData);
      try {
        // form
        await ToolRequest.request('PUT', `/v2/admin/signup-form/${signupFormId}/data`, data);

        // members
        if (membersData) {
          for (let memberData of membersData) {
            await ToolRequest.request('PUT', `/v2/admin/signup-form-family-member/${memberData.id}`, memberData);
          }
        }

        setAutosavingStatus('SAVED');
      } catch (err) {
        setAutosavingStatus('UNSAVED');

        if (err?.response?.data) {
          switch (err.response.data.errorCode) {
            case 'INVALID_USER': {
              return message.error(I18n.t('page1ScreenErrorMessageInvalidUser'));
            }
            case 'INVALID_TOKEN': {
              message.error(I18n.t('page3ScreenErrorMessageInvalidToken'));
              history.replace(`/${signupFormId}/page/1`);
              return;
            }
            default:
              return message.error(I18n.t('errorMessageUnknownError'));
          }
        }
        message.error(I18n.t('errorMessageUnknownError'));
      }
    }, forceNow ? 1 : 1800);
  };

  const currentMonthAfter1 = moment().startOf('month').subtract(3, 'month');
  const currentMonthAfter2 = moment().startOf('month').subtract(2, 'month');
  const currentMonthAfter3 = moment().startOf('month').subtract(1, 'month');

  const initialValues = formData.isRenewal ? {
    incomeProof: {
      periodAfter1: {
        year: formData.data.incomeProof?.periodAfter1?.year?.toString() || currentMonthAfter1.year(),
        month: formData.data.incomeProof?.periodAfter1?.month?.toString() || currentMonthAfter1.month() + 1,
        amount: formData.data.incomeProof?.periodAfter1?.amount?.toString() || '',
        imageIds: formData.data.incomeProof?.periodAfter1?.imageIds ? formData.data.incomeProof?.periodAfter1?.imageIds.filter(v => v).map(id => ({
          id,
        })) : [],
        yearAndMonth: (formData.data.incomeProof?.periodAfter1?.year && formData.data.incomeProof?.periodAfter1?.month
          ? moment().startOf('month').year(formData.data.incomeProof?.periodAfter1?.year).month(formData.data.incomeProof?.periodAfter1?.month - 1) : currentMonthAfter1),
      },
      periodAfter2: {
        year: formData.data.incomeProof?.periodAfter2?.year?.toString() || currentMonthAfter2.year(),
        month: formData.data.incomeProof?.periodAfter2?.month?.toString() || currentMonthAfter2.month() + 1,
        amount: formData.data.incomeProof?.periodAfter2?.amount?.toString() || '',
        imageIds: formData.data.incomeProof?.periodAfter2?.imageIds ? formData.data.incomeProof?.periodAfter2?.imageIds.filter(v => v).map(id => ({
          id,
        })) : [],
        yearAndMonth: (formData.data.incomeProof?.periodAfter2?.year && formData.data.incomeProof?.periodAfter2?.month
          ? moment().startOf('month').year(formData.data.incomeProof?.periodAfter2?.year).month(formData.data.incomeProof?.periodAfter2?.month - 1) : currentMonthAfter2),
      },
      periodAfter3: {
        year: formData.data.incomeProof?.periodAfter3?.year?.toString() || currentMonthAfter3.year(),
        month: formData.data.incomeProof?.periodAfter3?.month?.toString() || currentMonthAfter3.month() + 1,
        amount: formData.data.incomeProof?.periodAfter3?.amount?.toString() || '',
        imageIds: formData.data.incomeProof?.periodAfter3?.imageIds ? formData.data.incomeProof?.periodAfter3?.imageIds.filter(v => v).map(id => ({
          id,
        })) : [],
        yearAndMonth: (formData.data.incomeProof?.periodAfter3?.year && formData.data.incomeProof?.periodAfter3?.month
          ? moment().startOf('month').year(formData.data.incomeProof?.periodAfter3?.year).month(formData.data.incomeProof?.periodAfter3?.month - 1) : currentMonthAfter3),
      },
      exceptionReasonIndices: formData.data.incomeProof?.exceptionReasonIndices || [],
      exceptionReason: formData.data.incomeProof?.exceptionReason || '',
      fullName: formData.data.incomeProof?.fullName || '',
      idCard: formData.data.incomeProof?.idCard || '',
    },
  } : {
    members: formData.members.map(member => ({
      idCardImageIds: member.idCardImageIds.filter(v => v).map(id => ({
        id,
      })),
      idCardChecked: member.idCardChecked,
    })),
    incomeProof: {
      periodBefore: {
        year: formData.data.incomeProof?.periodBefore?.year?.toString() || '',
        month: formData.data.incomeProof?.periodBefore?.month?.toString() || '',
        amount: formData.data.incomeProof?.periodBefore?.amount?.toString() || '',
        imageIds: formData.data.incomeProof?.periodBefore?.imageIds ? formData.data.incomeProof?.periodBefore?.imageIds.filter(v => v).map(id => ({
          id,
        })) : [],
        yearAndMonth: (formData.data.incomeProof?.periodBefore?.year && formData.data.incomeProof?.periodBefore?.month
          ? moment().startOf('month').year(formData.data.incomeProof?.periodBefore?.year).month(formData.data.incomeProof?.periodBefore?.month - 1) : null),
      },
      periodAfter1: {
        year: formData.data.incomeProof?.periodAfter1?.year?.toString() || currentMonthAfter1.year(),
        month: formData.data.incomeProof?.periodAfter1?.month?.toString() || currentMonthAfter1.month() + 1,
        amount: formData.data.incomeProof?.periodAfter1?.amount?.toString() || '',
        imageIds: formData.data.incomeProof?.periodAfter1?.imageIds ? formData.data.incomeProof?.periodAfter1?.imageIds.filter(v => v).map(id => ({
          id,
        })) : [],
        yearAndMonth: (formData.data.incomeProof?.periodAfter1?.year && formData.data.incomeProof?.periodAfter1?.month
          ? moment().startOf('month').year(formData.data.incomeProof?.periodAfter1?.year).month(formData.data.incomeProof?.periodAfter1?.month - 1) : currentMonthAfter1),
      },
      periodAfter2: {
        year: formData.data.incomeProof?.periodAfter2?.year?.toString() || currentMonthAfter2.year(),
        month: formData.data.incomeProof?.periodAfter2?.month?.toString() || currentMonthAfter2.month() + 1,
        amount: formData.data.incomeProof?.periodAfter2?.amount?.toString() || '',
        imageIds: formData.data.incomeProof?.periodAfter2?.imageIds ? formData.data.incomeProof?.periodAfter2?.imageIds.filter(v => v).map(id => ({
          id,
        })) : [],
        yearAndMonth: (formData.data.incomeProof?.periodAfter2?.year && formData.data.incomeProof?.periodAfter2?.month
          ? moment().startOf('month').year(formData.data.incomeProof?.periodAfter2?.year).month(formData.data.incomeProof?.periodAfter2?.month - 1) : currentMonthAfter2),
      },
      periodAfter3: {
        year: formData.data.incomeProof?.periodAfter3?.year?.toString() || currentMonthAfter3.year(),
        month: formData.data.incomeProof?.periodAfter3?.month?.toString() || currentMonthAfter3.month() + 1,
        amount: formData.data.incomeProof?.periodAfter3?.amount?.toString() || '',
        imageIds: formData.data.incomeProof?.periodAfter3?.imageIds ? formData.data.incomeProof?.periodAfter3?.imageIds.filter(v => v).map(id => ({
          id,
        })) : [],
        yearAndMonth: (formData.data.incomeProof?.periodAfter3?.year && formData.data.incomeProof?.periodAfter3?.month
          ? moment().startOf('month').year(formData.data.incomeProof?.periodAfter3?.year).month(formData.data.incomeProof?.periodAfter3?.month - 1) : currentMonthAfter3),
      },
      exceptionReasonIndices: formData.data.incomeProof?.exceptionReasonIndices || [],
      exceptionReason: formData.data.incomeProof?.exceptionReason || '',
      fullName: formData.data.incomeProof?.fullName || '',
      idCard: formData.data.incomeProof?.idCard || '',
    },
    addressProofImageIds: formData.data.addressProofImageIds ? formData.data.addressProofImageIds.filter(v => v).map(id => ({
      id,
    })) : [],
    preferredKioskId: undefined,    // load later
    octopusCards: formData.data.octopusCards ? formData.data.octopusCards.map(octopusCard => ({
      card: octopusCard.card || '',
      checkDigit: octopusCard.checkDigit || '',
      noCheckDigitChecked: octopusCard.noCheckDigitChecked || false,
    })) : [],
    octopusCardsNoneChecked: formData.data.octopusCardsNoneChecked || false,
    foodSafetyChecked: formData.data.foodSafetyChecked || false,
    personalInfoClaimChecked: formData.data.personalInfoClaimChecked || false,
  };

  const [incomeProofNoneChecked, setIncomeProofNoneChecked] = useState(initialValues.incomeProof?.exceptionReasonIndices?.length);
  const [incomeProofOthersChecked, setIncomeProofOthersChecked] = useState(initialValues.incomeProof?.exceptionReasonIndices?.indexOf(5) > -1);
  const [octopusCardsNoneChecked, setOctopusCardsNoneChecked] = useState(initialValues.octopusCardsNoneChecked);

  return loaded ? (
    <>
      <Page3ScreenAutosavingMessage
        status={autosavingStatus}
        onReload={() => autosave(true)}
      />

      <Text type="success" strong id="subheaderRight">
        ({I18n.t('page1ScreenHeaderSubRight', {
          name: formData.user.nickname,
        })})
      </Text>
      <Title level={5} id="subheader">
        {I18n.t(formData.isRenewal ? 'page1ScreenHeaderSubRenewal' : 'page1ScreenHeaderSub')}
      </Title>
      <div id="mainContentContainer">
        <div>
          <Spin spinning={loading}>
            <Form
              {...formLayout}
              form={form}
              initialValues={initialValues}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              labelWrap
              scrollToFirstError
              onValuesChange={(changedValues, allValues) => autosave()}
            >
              <Form.Item
                {...formFullLayout}
              >
                <Alert type="info" message={I18n.t('page3ScreenHeaderInfo')} banner />
              </Form.Item>

              {formData.isRenewal ? (
                <>
                  <Title level={5}>
                    <Avatar style={{backgroundColor: '#87d068', marginRight: 8}}>A</Avatar>
                    {I18n.t('page3ScreenFormSectionIncomeProofTitle')}
                  </Title>
                  <Paragraph type="secondary" style={{ whiteSpace: 'pre-wrap' }}>{I18n.t(formData.isRenewal ? 'page3ScreenFormSectionIncomeProofDescriptionRenewal' : 'page3ScreenFormSectionIncomeProofDescription')}</Paragraph>

                  <Form.Item
                    {...formFullLayout}
                  >
                    <List
                      bordered
                      dataSource={[{
                        key: 'periodAfter1',
                        label: I18n.t('page3ScreenFormIncomeProofPeriodAfterRenewalLabel', {
                          year: currentMonthAfter1.format('YYYY'),
                          month: currentMonthAfter1.format('M'),
                        }),
                      }, {
                        key: 'periodAfter2',
                        label: I18n.t('page3ScreenFormIncomeProofPeriodAfterRenewalLabel', {
                          year: currentMonthAfter2.format('YYYY'),
                          month: currentMonthAfter2.format('M'),
                        }),
                      }, {
                        key: 'periodAfter3',
                        label: I18n.t('page3ScreenFormIncomeProofPeriodAfterRenewalLabel', {
                          year: currentMonthAfter3.format('YYYY'),
                          month: currentMonthAfter3.format('M'),
                        }),
                      }]}
                      renderItem={(item, index) => (
                        <List.Item key={index}>
                          <Space direction="vertical" style={{width: '100%'}}>
                            <div>
                              <Avatar style={{background: '#f56a00'}} size="small">{index + 1}</Avatar>
                              <Text strong style={{marginLeft: 16}}>{item.label}</Text>
                            </div>

                            <Form.Item
                              {...formLayout}
                              name={['incomeProof', item.key, 'yearAndMonth']}
                              label={I18n.t('page3ScreenFormIncomeProofYearAndMonthLabel')}
                              rules={[
                                {
                                  required: true,
                                  message: I18n.t('page3ScreenFormIncomeProofYearAndMonthErrorMessage'),
                                },
                              ]}
                              hasFeedback
                            >
                              <DatePicker
                                picker="month"
                                disabled
                                disabledDate={currentDate => currentDate && (currentDate.valueOf() > Date.now() || moment(currentDate).endOf('day').isAfter(moment(formData.createdAt).startOf('month').subtract(3, 'month')))}
                              />
                            </Form.Item>

                            <Form.Item
                              {...formLayout}
                              name={['incomeProof', item.key, 'amount']}
                              label={I18n.t('page3ScreenFormIncomeProofAmountLabel')}
                              rules={[
                                {
                                  required: true,
                                  message: I18n.t('errorMessageRequiredInputAny', {
                                    name: I18n.t('page3ScreenFormIncomeProofAmountLabel'),
                                  }),
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (value >= 0) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(I18n.t('page3ScreenFormIncomeProofAmountErrorMessageInvalid')));
                                  },
                                }),
                              ]}
                              hasFeedback
                            >
                              <Input prefix="$" />
                            </Form.Item>

                            <Form.Item
                              {...formLayout}
                              name={['incomeProof', item.key, 'imageIds']}
                              label={I18n.t('page3ScreenFormIncomeProofImageLabel')}
                              rules={[
                                ...(!incomeProofNoneChecked ? [
                                  {
                                    required: true,
                                    message: I18n.t('errorMessageRequiredUploadAny', {
                                      name: I18n.t('page3ScreenFormIncomeProofImageLabel'),
                                    }),
                                  },
                                ] : []),
                              ]}
                              tooltip={I18n.t('page3ScreenFormIncomeProofImageTooltip')}
                            >
                              <UploadFile
                                maxCount={10}
                              />
                            </Form.Item>
                          </Space>
                        </List.Item>
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    label={I18n.t('page3ScreenFormIncomeProofExceptionReasonIndicesLabel')}
                  >
                    <Form.Item
                      noStyle
                      name={['incomeProof', 'exceptionReasonIndices']}
                    >
                      <Checkbox.Group
                        options={[
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices0Label'),
                            value: 0,
                          },
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices1Label'),
                            value: 1,
                          },
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices2Label'),
                            value: 2,
                          },
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices3Label'),
                            value: 3,
                          },
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices4Label'),
                            value: 4,
                          },
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices6Label'),
                            value: 6,
                          },
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices5Label'),
                            value: 5,
                          },
                        ]}
                        onChange={values => {
                          setIncomeProofNoneChecked(values.length);
                          setIncomeProofOthersChecked(values.indexOf(5) > -1);
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      noStyle
                      name={['incomeProof', 'exceptionReason']}
                      dependencies={['incomeProof', 'exceptionReasonIndices']}
                      rules={incomeProofOthersChecked ? [
                        {
                          required: true,
                          message: I18n.t('page3ScreenFormIncomeProofExceptionReasonErrorMessage'),
                        },
                      ] : []}
                    >
                      <Input
                        placeholder={I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices5Label')}
                        disabled={!incomeProofOthersChecked}
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={I18n.t('page3ScreenFormIncomeProofClaimLabel')}
                    required={true}
                  >
                    <div>
                      <Input.Group compact>
                        <span style={{marginRight: 8}}>{I18n.t('page3ScreenFormIncomeProofClaimContentPre')}</span>
                        <Form.Item
                          name={['incomeProof', 'fullName']}
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: I18n.t('errorMessageRequiredInputAny', {
                                name: I18n.t('page3ScreenFormIncomeProofClaimFullNameLabel'),
                              }),
                            },
                          ]}
                        >
                          <Input
                            style={{width: 160}}
                            placeholder={I18n.t('page3ScreenFormIncomeProofClaimFullNameLabel')}
                          />
                        </Form.Item>
                        <span style={{marginLeft: 8, marginRight: 8}}>({I18n.t('page3ScreenFormIncomeProofClaimIdCardLabel')}: </span>
                        <Form.Item
                          name={['incomeProof', 'idCard']}
                          noStyle
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value.card && value.card.length === 7 && value.checkDigit && value.valid) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error(I18n.t('page3ScreenFormIncomeProofClaimIdCardErrorMessage')));
                              },
                            }),
                          ]}
                        >
                          <HKIDInput />
                        </Form.Item>
                        <span style={{marginRight: 8}}>)</span>
                      </Input.Group>
                    </div>
                    <div>{I18n.t('page3ScreenFormIncomeProofClaimContentPost')}</div>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Title level={5}>
                    <Avatar style={{backgroundColor: '#87d068', marginRight: 8}}>A</Avatar>
                    {I18n.t('page3ScreenFormSectionMemberTitle')}
                  </Title>
                  <Paragraph type="secondary">{I18n.t('page3ScreenFormSectionMemberDescription')}</Paragraph>

                  <Form.Item
                    label={I18n.t('page3ScreenFormMembersCountLabel')}
                  >
                    {formData.members.length}
                  </Form.Item>

                  <Form.Item
                    {...formFullLayout}
                    name="members"
                    valuePropName="dataSource"
                    getValueFromEvent={() => form.getFieldValue('members')}
                  >
                    <List
                      bordered
                      renderItem={(item, index) => (
                        <List.Item key={index}>
                          <Space direction="vertical" style={{width: '100%'}}>
                            <div>
                              <Avatar size="small">{index + 1}</Avatar>
                              <Text strong style={{marginLeft: 16}}>
                                {I18n.t('page3ScreenFormMemberTitle', {
                                  index: index + 1,
                                })}
                              </Text>
                            </div>

                            <Form.Item
                              {...formLayout}
                              label={I18n.t('page3ScreenFormMemberNameChinese')}
                            >
                              {formData.members[index].lastname} {formData.members[index].firstname}
                              {formData.members[index].fullNameChinese && ` (${formData.members[index].fullNameChinese})`}
                            </Form.Item>

                            <Form.Item
                              {...formLayout}
                              name={['members', index, 'idCardImageIds']}
                              label={I18n.t('page3ScreenFormMemberIdCardImage')}
                              required
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!getFieldValue(['members', index, 'idCardChecked']) && (!value || !value.length)) {
                                      return Promise.reject(new Error(I18n.t('errorMessageRequiredUploadAny', {
                                        name: I18n.t('page3ScreenFormMemberIdCardImage'),
                                      })));
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              {initialValues.members[index]?.idCardChecked ? (
                                <div style={{ width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', border: '1px solid #d9d9d9' }}>
                                  <CheckOutlined style={{ color: '#52c41a' }} />
                                  <div>{I18n.t('page3ScreenFormMemberIdCardCheckedLabel')}</div>
                                </div>
                              ) : (
                                <UploadFile
                                  maxCount={1}
                                />
                              )}
                            </Form.Item>
                          </Space>
                        </List.Item>
                      )}
                    />
                  </Form.Item>

                  <Divider />
                  <Title level={5}>
                    <Avatar style={{backgroundColor: '#87d068', marginRight: 8}}>B</Avatar>
                    {I18n.t('page3ScreenFormSectionAddressProofTitle')}
                  </Title>
                  <Paragraph type="secondary">{I18n.t('page3ScreenFormSectionAddressProofDescription')}</Paragraph>

                  <Form.Item
                    label={I18n.t('page3ScreenFormAddressProofImage')}
                    name="addressProofImageIds"
                    rules={[
                      {
                        required: true,
                        message: I18n.t('errorMessageRequiredUploadAny', {
                          name: I18n.t('page3ScreenFormAddressProofImage'),
                        }),
                      },
                    ]}
                  >
                    <UploadFile
                      maxCount={1}
                    />
                  </Form.Item>

                  <Divider />

                  <Title level={5}>
                    <Avatar style={{backgroundColor: '#87d068', marginRight: 8}}>C</Avatar>
                    {I18n.t('page3ScreenFormSectionIncomeProofTitle')}
                  </Title>
                  <Paragraph type="secondary" style={{ whiteSpace: 'pre-wrap' }}>{I18n.t('page3ScreenFormSectionIncomeProofDescription')}</Paragraph>

                  <Form.Item
                    {...formFullLayout}
                  >
                    <List
                      bordered
                      dataSource={[{
                        key: 'periodBefore',
                        label: I18n.t('page3ScreenFormIncomeProofPeriodBeforeLabel'),
                      }, {
                        key: 'periodAfter1',
                        label: I18n.t('page3ScreenFormIncomeProofPeriodAfter1Label'),
                      }, {
                        key: 'periodAfter2',
                        label: I18n.t('page3ScreenFormIncomeProofPeriodAfter2Label'),
                      }, {
                        key: 'periodAfter3',
                        label: I18n.t('page3ScreenFormIncomeProofPeriodAfter3Label'),
                      }]}
                      renderItem={(item, index) => (
                        <List.Item key={index}>
                          <Space direction="vertical" style={{width: '100%'}}>
                            <div>
                              <Avatar style={{background: '#f56a00'}} size="small">{index + 1}</Avatar>
                              <Text strong style={{marginLeft: 16}}>{item.label}</Text>
                            </div>

                            <Form.Item
                              {...formLayout}
                              name={['incomeProof', item.key, 'yearAndMonth']}
                              label={I18n.t('page3ScreenFormIncomeProofYearAndMonthLabel')}
                              rules={[
                                {
                                  required: true,
                                  message: I18n.t('page3ScreenFormIncomeProofYearAndMonthErrorMessage'),
                                },
                              ]}
                              hasFeedback
                            >
                              <DatePicker
                                picker="month"
                                disabled={index > 0}
                                disabledDate={currentDate => currentDate && (currentDate.valueOf() > Date.now() || moment(currentDate).endOf('day').isAfter(moment(formData.createdAt).startOf('month').subtract(3, 'month'))) || currentDate.isBefore(moment('2019-11', 'YYYY-MM'))}
                              />
                            </Form.Item>

                            <Form.Item
                              {...formLayout}
                              name={['incomeProof', item.key, 'amount']}
                              label={I18n.t('page3ScreenFormIncomeProofAmountLabel')}
                              rules={[
                                {
                                  required: true,
                                  message: I18n.t('errorMessageRequiredInputAny', {
                                    name: I18n.t('page3ScreenFormIncomeProofAmountLabel'),
                                  }),
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (value >= 0) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(I18n.t('page3ScreenFormIncomeProofAmountErrorMessageInvalid')));
                                  },
                                }),
                              ]}
                              hasFeedback
                            >
                              <Input prefix="$" />
                            </Form.Item>

                            <Form.Item
                              {...formLayout}
                              name={['incomeProof', item.key, 'imageIds']}
                              label={I18n.t('page3ScreenFormIncomeProofImageLabel')}
                              rules={[
                                ...(!incomeProofNoneChecked ? [
                                  {
                                    required: true,
                                    message: I18n.t('errorMessageRequiredUploadAny', {
                                      name: I18n.t('page3ScreenFormIncomeProofImageLabel'),
                                    }),
                                  },
                                ] : []),
                              ]}
                              tooltip={I18n.t('page3ScreenFormIncomeProofImageTooltip')}
                            >
                              <UploadFile
                                maxCount={10}
                              />
                            </Form.Item>
                          </Space>
                        </List.Item>
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    label={I18n.t('page3ScreenFormIncomeProofExceptionReasonIndicesLabel')}
                  >
                    <Form.Item
                      noStyle
                      name={['incomeProof', 'exceptionReasonIndices']}
                    >
                      <Checkbox.Group
                        options={[
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices0Label'),
                            value: 0,
                          },
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices1Label'),
                            value: 1,
                          },
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices2Label'),
                            value: 2,
                          },
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices3Label'),
                            value: 3,
                          },
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices4Label'),
                            value: 4,
                          },
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices6Label'),
                            value: 6,
                          },
                          {
                            label: I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices5Label'),
                            value: 5,
                          },
                        ]}
                        onChange={values => {
                          setIncomeProofNoneChecked(values.length);
                          setIncomeProofOthersChecked(values.indexOf(5) > -1);
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      noStyle
                      name={['incomeProof', 'exceptionReason']}
                      dependencies={['incomeProof', 'exceptionReasonIndices']}
                      rules={incomeProofOthersChecked ? [
                        {
                          required: true,
                          message: I18n.t('page3ScreenFormIncomeProofExceptionReasonErrorMessage'),
                        },
                      ] : []}
                    >
                      <Input
                        placeholder={I18n.t('page3ScreenFormIncomeProofExceptionReasonIndices5Label')}
                        disabled={!incomeProofOthersChecked}
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={I18n.t('page3ScreenFormIncomeProofClaimLabel')}
                    required={true}
                  >
                    <div>
                      <Input.Group compact>
                        <span style={{marginRight: 8}}>{I18n.t('page3ScreenFormIncomeProofClaimContentPre')}</span>
                        <Form.Item
                          name={['incomeProof', 'fullName']}
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: I18n.t('errorMessageRequiredInputAny', {
                                name: I18n.t('page3ScreenFormIncomeProofClaimFullNameLabel'),
                              }),
                            },
                          ]}
                        >
                          <Input
                            style={{width: 160}}
                            placeholder={I18n.t('page3ScreenFormIncomeProofClaimFullNameLabel')}
                          />
                        </Form.Item>
                        <span style={{marginLeft: 8, marginRight: 8}}>({I18n.t('page3ScreenFormIncomeProofClaimIdCardLabel')}: </span>
                        <Form.Item
                          name={['incomeProof', 'idCard']}
                          noStyle
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value.card && value.card.length === 7 && value.checkDigit && value.valid) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error(I18n.t('page3ScreenFormIncomeProofClaimIdCardErrorMessage')));
                              },
                            }),
                          ]}
                        >
                          <HKIDInput />
                        </Form.Item>
                        <span style={{marginRight: 8}}>)</span>
                      </Input.Group>
                    </div>
                    <div>{I18n.t('page3ScreenFormIncomeProofClaimContentPost')}</div>
                  </Form.Item>

                  <Divider />
                  <Title level={5}>
                    <Avatar style={{backgroundColor: '#87d068', marginRight: 8}}>D</Avatar>
                    {I18n.t('page3ScreenFormSectionOctopusCardsTitle')}
                  </Title>
                  <Paragraph type="secondary">
                    {I18n.t('page3ScreenFormSectionOctopusCardsDescription')}
                    <Page3ScreenOctopusHelpModalButton />
                  </Paragraph>

                  {!octopusCardsNoneChecked && (
                    <Form.Item
                      {...formFullLayout}
                    >
                      <List
                        bordered
                        dataSource={[0, 1]}
                        renderItem={(item, index) => (
                          <List.Item key={index}>
                            <Space direction="vertical" style={{width: '100%'}}>
                              <div>
                                <Avatar style={{background: '#f5d000'}} size="small">{index + 1}</Avatar>
                                <Text strong style={{marginLeft: 16}}>
                                  {I18n.t('page3ScreenFormOctopusCardTitle', {
                                    index: item + 1,
                                  })}
                                </Text>
                              </div>

                              <Form.Item
                                {...formLayout}
                                name={['octopusCards', item]}
                                label={I18n.t('page3ScreenFormOctopusCardLabel')}
                                required={!index}
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if ((index > 0 && !value) || (value && (value.valid || (index > 0 && (!value.card && (!value.checkDigit || value.noCheckDigitChecked)))))) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(I18n.t('page3ScreenFormOctopusCardErrorMessage')));
                                    },
                                  }),
                                  ...(index === 1 ? [
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (value && (value.card || value.checkDigit) && value.valid && getFieldValue(['octopusCards', 0]).card === value.card) {
                                          return Promise.reject(new Error(I18n.t('page3ScreenFormOctopusCardErrorMessageDuplicate')));
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ] : []),
                                ]}
                              >
                                <OctopusInput />
                              </Form.Item>
                            </Space>
                          </List.Item>
                        )}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    {...formTailLayout}
                    name="octopusCardsNoneChecked"
                    valuePropName="checked"
                  >
                    <Checkbox
                      onChange={e => setOctopusCardsNoneChecked(e.target.checked)}
                    >
                      {I18n.t('page3ScreenFormOctopusCardNoneCheckedText')}
                    </Checkbox>
                  </Form.Item>

                  <Divider />
                  <Title level={5}>
                    <Avatar style={{backgroundColor: '#87d068', marginRight: 8}}>E</Avatar>
                    {I18n.t('page3ScreenFormSectionPreferredKioskTitle')}
                  </Title>
                  <Paragraph type="secondary">
                    {I18n.t('page3ScreenFormSectionPreferredKioskDescription')}
                  </Paragraph>

                  <Form.Item
                    label={I18n.t('page3ScreenFormPreferredKioskLabel')}
                  >
                    <Space direction="vertical" style={{width: '100%'}}>
                      <Select
                        placeholder={I18n.t('page3ScreenFormPreferredKioskAreaLabel')}
                        onChange={value => {
                          setPreferredKioskIdAreaId(value);
                          const kiosksMatched = kiosksByArea.find(area => area.id === value).kiosks.filter(kiosk => kiosk.isSelectable);
                          form.setFieldsValue({
                            preferredKioskId: kiosksMatched.length ? kiosksMatched[0].id : null,
                          });
                          autosave();
                        }}
                        value={preferredKioskIdAreaId}
                      >
                        {kiosksByArea.map(area => (
                          <Option key={area.id} value={area.id}>
                            {area.name[formData.locale]} ({area.kiosks.length})
                          </Option>
                        ))}
                      </Select>

                      <Form.Item
                        name="preferredKioskId"
                        rules={[
                          {
                            required: true,
                            message: I18n.t('errorMessageRequiredSelectAny', {
                              name: I18n.t('page3ScreenFormPreferredKioskLabel'),
                            }),
                          },
                        ]}
                        hasFeedback
                      >
                        <Select
                          placeholder={I18n.t('page3ScreenFormPreferredKioskLabel')}
                          disabled={!preferredKioskIdAreaId}
                        >
                          {preferredKioskIdAreaId && kiosksByArea.find(area => area.id === preferredKioskIdAreaId).kiosks.map(kiosk => (
                            <Option
                              key={kiosk.id}
                              value={kiosk.id}
                              disabled={!kiosk.isSelectable}
                            >
                              <div>
                                {kiosk.name[formData.locale]}
                                {!kiosk.isSelectable && (
                                  <span style={{ marginLeft: 8 }}>{I18n.t('page3ScreenFormPreferredKioskDisabledLabel')}</span>
                                )}
                              </div>
                              <div><Text type="secondary" style={{whiteSpace: 'normal'}}>{kiosk.address[formData.locale]}</Text></div>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Space>
                  </Form.Item>

                  <Divider />
                  <Title level={5}>
                    <Avatar style={{backgroundColor: '#87d068', marginRight: 8}}>F</Avatar>
                    {I18n.t('page3ScreenFormSectionFoodSafetyAndPersonalInfoClaimTitle')}
                  </Title>

                  <Form.Item
                    label={I18n.t('page3ScreenFormFoodSafetyLabel')}
                    name="foodSafetyChecked"
                    required
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(I18n.t('page3ScreenFormFoodSafetyErrorMessage')));
                        },
                      }),
                    ]}
                  >
                    <ForceOpeningCheckbox
                      url={`/static/media/${formData.locale === 'en' ? foodSafetyAgreementFileEn : foodSafetyAgreementFileZh}`}
                      buttonLabel={I18n.t('page3ScreenFoodSafetyCheckboxButtonLabel')}
                      checkboxLabel={I18n.t('page3ScreenFoodSafetyCheckboxText')}
                      errorMessage={I18n.t('page3ScreenFoodSafetyCheckboxButtonErrorMessage')}
                    />
                  </Form.Item>

                  <Form.Item
                    label={I18n.t('page3ScreenFormPersonalInfoClaimLabel')}
                    name="personalInfoClaimChecked"
                    required
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(I18n.t('page3ScreenFormPersonalInfoClaimErrorMessage')));
                        },
                      }),
                    ]}
                  >
                    <ForceOpeningCheckbox
                      url={`/static/media/${personalInfoCollectionAgreementFile}`}
                      buttonLabel={I18n.t('page3ScreenFormPersonalInfoClaimButtonLabel')}
                      checkboxLabel={I18n.t('page3ScreenFormPersonalInfoClaimCheckboxText')}
                      errorMessage={I18n.t('page3ScreenFormPersonalInfoClaimCheckboxButtonErrorMessage')}
                    />
                  </Form.Item>
                </>
              )}

              <Form.Item
                {...formTailLayout}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={autosavingStatus !== 'SAVED'}
                  loading={autosavingStatus !== 'SAVED'}
                  block
                >
                  {I18n.t('page3ScreenButtonSubmitLabel')}
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    </>
  ) : <SpinFull />;
};
