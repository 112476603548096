import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Typography, Radio, Button, Form, Space, message, Spin, Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { updateForm } from '../../reducers/formSlice';
import * as ToolRequest from '../../tools/ToolRequest';

const {Title, Text, Paragraph} = Typography

const formTailLayout = {
  wrapperCol: {
    md: { offset: 6, span: 16 },
  },
};

const formFullLayout = {
  wrapperCol: {
    md: { span: 24 },
  },
};

export default function Page2Screen (props) {
  const {signupFormId} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const formData = useSelector(state => state.form.value);

  const [loading, setLoading] = useState(false);

  const {foodangelVendingUrlZh, foodangelVendingUrlEn} = window.getConfig();
  const foodangelVendingUrl = formData.locale === 'en' ? foodangelVendingUrlEn : foodangelVendingUrlZh;

  const onFinish = async (values) => {
    switch (values.status) {
      case 'CONFIRM': {
        history.push(`/${signupFormId}/page/3`);
        break;
      }
      case 'REJECT':
      case 'SELECT_HOT_MEAL':
      case 'SELECT_EFOOD_BANK': {

        const title = formData.isRenewal ? {
          'REJECT': I18n.t('page2ScreenStatusRenewalModalConfirmTitleReject'),
        }[values.status] : {
          'REJECT': I18n.t('page2ScreenStatusModalConfirmTitleReject'),
          'SELECT_HOT_MEAL': I18n.t('page2ScreenStatusModalConfirmTitleSelectHotMeal'),
          'SELECT_EFOOD_BANK': I18n.t('page2ScreenStatusModalConfirmTitleSelectEfoodBank'),
        }[values.status];

        const content = formData.isRenewal ? {
          'REJECT': I18n.t('page2ScreenStatusRenewalModalConfirmContentReject'),
        }[values.status] : {
          'REJECT': I18n.t('page2ScreenStatusModalConfirmContentReject'),
          'SELECT_HOT_MEAL': I18n.t('page2ScreenStatusModalConfirmContentSelectHotMeal'),
          'SELECT_EFOOD_BANK': I18n.t('page2ScreenStatusModalConfirmContentSelectEfoodBank'),
        }[values.status];

        Modal.confirm({
          title,
          content,
          icon: <WarningOutlined />,
          okText: I18n.t('page2ScreenStatusModalConfirmButtonConfirmLabel'),
          okType: 'danger',
          cancelText: I18n.t('page2ScreenStatusModalConfirmButtonCancelLabel'),
          onOk: () => {
            setLoading(true);

            (async () => {
              try {
                const statusNew = {
                  'REJECT': 'REJECTED',
                  'SELECT_HOT_MEAL': 'SELECTED_HOT_MEAL',
                  'SELECT_EFOOD_BANK': 'SELECTED_EFOOD_BANK',
                }[values.status];

                const res = await ToolRequest.request('POST', `/v2/admin/signup-form/${signupFormId}/submit`, {
                  status: statusNew,
                });

                dispatch(updateForm({
                  status: statusNew,
                }));

                // TODO
                history.push(`/${signupFormId}/page/4`);
              } catch (err) {
                setLoading(false);

                if (err?.response?.data) {
                  switch (err.response.data.errorCode) {
                    case 'NOT_FOUND': {
                      return message.error(I18n.t('page1ScreenErrorMessageInvalidUser'));
                    }
                    default:
                      return message.error(I18n.t('errorMessageUnknownError'));
                  }
                }
                message.error(I18n.t('errorMessageUnknownError'));
              }
            })();
          },
        });
        break;
      }
    }
  }

  return (
    <>
      <Text type="success" strong id="subheaderRight">
        ({I18n.t('page1ScreenHeaderSubRight', {
          name: formData.user.nickname,
        })})
      </Text>
      <Title level={5} id="subheader">
        {I18n.t(formData.isRenewal ? 'page1ScreenHeaderSubRenewal' : 'page1ScreenHeaderSub')}
      </Title>
      <div id="mainContentContainer">
        <div>
          <Spin spinning={loading}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                {...formFullLayout}
              >
                <Title level={5}>{I18n.t('page2ScreenTitle')}</Title>
              </Form.Item>

              <Form.Item
                {...formFullLayout}
              >
                <Typography>
                  {formData.isRenewal ? (
                    <>
                      <Paragraph>
                        {I18n.t('page2ScreenIntroductionRenewal1')}
                      </Paragraph>
                      <Paragraph>
                        {I18n.t('page2ScreenIntroductionRenewal2')}
                      </Paragraph>
                    </>
                  ) : (
                    <>
                      <Paragraph>
                        {I18n.t('page2ScreenIntroduction1')}
                      </Paragraph>
                      <Paragraph>
                        {I18n.t('page2ScreenIntroduction2')}<a target="_blank" href={foodangelVendingUrl}>{foodangelVendingUrl}</a>
                      </Paragraph>
                      <Paragraph>
                        {I18n.t('page2ScreenIntroduction3')}
                      </Paragraph>
                    </>
                  )}
                </Typography>
              </Form.Item>

              <Form.Item
                label={I18n.t('page2ScreenFormStatusLabel')}
                name="status"
                rules={[
                  {
                    required: true,
                    message: I18n.t('page2ScreenFormStatusErrorMessage'),
                  },
                ]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {formData.isRenewal ? (
                      <>
                        <Radio value="REJECT">
                          {I18n.t('page2ScreenIntroductionRenewalOption1')}
                        </Radio>
                        <Radio value="CONFIRM">
                          {I18n.t('page2ScreenIntroductionRenewalOption2')}
                        </Radio>
                      </>
                    ) : (
                      <>
                        <Radio value="CONFIRM">
                          {I18n.t('page2ScreenIntroductionOption1')}
                        </Radio>
                        <Radio value="REJECT">
                          {I18n.t('page2ScreenIntroductionOption2')}
                        </Radio>
                      </>
                    )}
                  </Space>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                {...formTailLayout}
              >
                <Button type="primary" htmlType="submit" block>
                  {I18n.t('page2ScreenButtonSubmitLabel')}
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    </>
  );
};
